import React, { Component } from 'react';
import { connect } from 'react-redux';
import { functions } from '../firebase/firebase';
import { db } from '../firebase';
import styles from './TwitterLogin.module.css';
import twitterLogo from '../images/Twitter_Logo_WhiteOnBlue.png';

class TwitterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twitterAuthorized: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    db.getTwitterInfoUpdate(user.id, (snapshot) => {
      const value = snapshot.val();
      if (value && value.authenticated) {
        this.setState({
          twitterAuthorized: true,
        });
      } else {
        this.setState({
          twitterAuthorized: false,
        });
      }
    });
  }

  authorizeTwitter = () => {
    const getRequestToken = functions.httpsCallable('twitterRequestToken');
    getRequestToken()
      .then((response) => {
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${response.data.oauthRequestToken}`;
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  deauthorizeTwitter = () => {
    const { user } = this.props;
    db.clearTwitterInfo(user.id);
  }

  render() {
    const { twitterAuthorized } = this.state;

    if (twitterAuthorized) {
      return (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div>
              <div className={styles.innerImageContainer}>
                <span className="material-icons text-success">check_circle</span>
              </div>
            </div>
            <img src={twitterLogo} className={styles.logo} alt="Twitter Logo" />
            <span className={styles.authText}>Twitter</span>
          </div>
          <button
            type="button"
            className="btn btn-warning"
            onClick={this.deauthorizeTwitter}
          >
            Deauthorize
          </button>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div>
            <div className={styles.innerImageContainer}>
              <span className="material-icons text-danger">offline_bolt</span>
            </div>
          </div>
          <img src={twitterLogo} className={styles.logo} alt="Twitter Logo" />
          <span className={styles.authText}>Twitter</span>
        </div>
        <button
          type="button"
          className="btn btn-warning"
          onClick={this.authorizeTwitter}
        >
          Authorize
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TwitterLogin);
