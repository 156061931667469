import { find } from 'lodash';
import { db } from '../firebase';

const setArticles = (articles) => ({
  type: 'SET_ARTICLES',
  articles,
});

let lastSearchDate = null;

const startGetArticles = (date) => (dispatch, getState) => {
  if (date) {
    lastSearchDate = date;
  }
  const uid = getState().auth.user.id;
  return db.getArticles(uid, date || lastSearchDate).then((articles) => {
    dispatch(setArticles(articles));
  });
};

const startUpdateArticle = (article) => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  return db.updateArticle(uid, article).then(() => dispatch(startGetArticles()));
};

const startCreateArticle = (article) => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  const { articles } = getState().articles;

  return new Promise((resolve) => {
    if (find(articles, { link: article.link })) {
      resolve();
      return;
    }

    db.createArticle(uid, article)
      .then(() => {
        dispatch(startGetArticles());
        resolve();
      })
      .catch(() => {
        resolve();
      });
  });
};

const startCreateMultipleArticles = (articles) => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  const currentArticles = getState().articles.articles;

  return new Promise((resolve) => {
    const promises = articles.map((article) => new Promise((innerResolve) => {
      if (find(currentArticles, { link: article.link })) {
        innerResolve();
        return;
      }

      db.createArticle(uid, article)
        .then(() => {
          innerResolve();
        })
        .catch(() => {
          innerResolve();
        });
    }));

    Promise.all(promises).then(() => {
      dispatch(startGetArticles());
      resolve();
    });
  });
};


export {
  setArticles,
  startGetArticles,
  startUpdateArticle,
  startCreateArticle,
  startCreateMultipleArticles,
};
