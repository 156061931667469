import { db } from '../firebase';

const setSettings = (settings) => ({
  type: 'SET_SETTINGS',
  settings,
});

const startGetSettings = () => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  return db.getSettings(uid).then((settings) => {
    dispatch(setSettings(settings || {}));
  });
};

const startSetSettings = (settings) => (dispatch, getState) => {
  if (getState().auth && getState().auth.user) {
    const uid = getState().auth.user.id;
    return db.setSettings(uid, settings).then(() => dispatch(startGetSettings()));
  }

  return dispatch(setSettings(settings));
};

export {
  startGetSettings,
  startSetSettings,
};
