import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { startGetSites } from '../actions/sites';
import SiteList from './SiteList';
import withAuthorization from './withAuthorization';
import MainContainer from './MainContainer';

class ArticlesContainer extends Component {
  componentDidMount() {
    const { getSites } = this.props;
    getSites();
  }

  render() {
    return (
      <MainContainer>
        <SiteList />
      </MainContainer>
    );
  }
}

ArticlesContainer.propTypes = {
  getSites: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getSites: () => dispatch(startGetSites()),
});

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(connect(mapStateToProps, mapDispatchToProps)(ArticlesContainer));
