import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy } from 'lodash';
import { startUpdateArticle, startGetArticles } from '../actions/articles';
import styles from './ArticleList.module.css';
import Article from './Article';
import PostArticleModal from './PostArticleModal';
import ArticleListHeader from './ArticleListHeader';
import ConfirmModal from './ConfirmModal';
import { functions } from '../firebase/firebase';
import BoardTownBlast from '../images/BoardTownBlast.png';

class ArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPostModalOpen: false,
      postModalArticle: null,
      isProcessingSites: false,
      articleFilters: {
        searchText: '',
        showUnpublished: true,
        showPublished: false,
        showHidden: false,
      },
    };
  }

  onPollAll = () => {
    this.setState({
      isProcessingSites: true,
    });
  };

  onConfirmPollAll = () => {
    this.setState({
      isProcessingSites: false,
    });
    const parseSites = functions.httpsCallable('parseAllSites');
    parseSites();
  };

  onCancelPollAll = () => {
    this.setState({
      isProcessingSites: false,
    });
  };

  onPost = (article) => {
    this.setState({
      isPostModalOpen: true,
      postModalArticle: article,
    });
  };

  onPostSuccess = () => {
    const { updateArticle } = this.props;
    const { postModalArticle } = this.state;

    updateArticle({
      ...postModalArticle,
      postedOn: moment().format(),
      posted: true,
    });

    this.setState({
      isPostModalOpen: false,
      postModalArticle: null,
    });
  };

  onPostCancel = () => {
    this.setState({
      isPostModalOpen: false,
      postModalArticle: null,
    });
  };

  onHideArticle = (article) => {
    const { updateArticle } = this.props;

    updateArticle({
      ...article,
      hidden: !article.hidden,
      hiddenOn: moment().format(),
    });
  };

  onNewDate = (date) => {
    const { getArticles } = this.props;
    getArticles(moment(date).format('YYYYMMDD'));
  };

  updateFilters = (articleFilters) => {
    this.setState({
      articleFilters,
    });
  };

  doesArticleContainSearchText = (article) => {
    const { articleFilters } = this.state;
    const { searchText } = articleFilters;

    if (searchText) {
      const titleIncludes = article.title && article.title.includes(searchText);
      const snippetIncludes = article.contentSnippet && article.contentSnippet.includes(searchText);
      return titleIncludes || snippetIncludes;
    }

    return true;
  };

  render() {
    const { articles } = this.props;
    const { isPostModalOpen, postModalArticle, isProcessingSites, articleFilters } = this.state;

    const { searchText, showPublished, showUnpublished, showHidden } = articleFilters;

    const filteredArticles = articles
      ? articles
        .filter((article) => {
          if (showPublished) {
            if (!article.posted || article.hidden) {
              return false;
            }

            return this.doesArticleContainSearchText(article);
          }

          if (showUnpublished) {
            if (article.posted || article.hidden) {
              return false;
            }

            return this.doesArticleContainSearchText(article);
          }

          if (showHidden) {
            if (!article.hidden) {
              return false;
            }

            return this.doesArticleContainSearchText(article);
          }

          return true;
        })
        .map((article) => ({
          ...article,
          image: article.image || BoardTownBlast,
        }))
      : articles;

    return (
      <div className={styles.container}>
        <ArticleListHeader onPollAll={this.onPollAll} onNewDate={this.onNewDate} updateFilters={this.updateFilters} currentFilters={articleFilters} />
        <div className={styles.contentBox}>
          {!filteredArticles || filteredArticles.length === 0 ? (
            <div className={styles.statusText}>There are no new articles to view.</div>
          ) : (
            filteredArticles.map((article) => (
              <Article key={article.id} article={article} onPost={this.onPost} onHide={this.onHideArticle} searchText={searchText} />
            ))
          )}
        </div>
        <PostArticleModal isOpen={isPostModalOpen} article={postModalArticle} onSuccess={this.onPostSuccess} onCancel={this.onPostCancel} />
        {isProcessingSites && (
          <ConfirmModal
            isOpen={isProcessingSites}
            title="Process all sites confirmation"
            onConfirm={this.onConfirmPollAll}
            onCancel={this.onCancelPollAll}
          >
            <div>Are you sure you want to process all sites?</div>
          </ConfirmModal>
        )}
      </div>
    );
  }
}

ArticleList.defaultProps = {
  articles: null,
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  updateArticle: PropTypes.func.isRequired,
  getArticles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  articles: orderBy(state.articles.articles, ['siteOrigin']),
});

const mapDispatchToProps = (dispatch) => ({
  updateArticle: (article) => dispatch(startUpdateArticle(article)),
  getArticles: (date) => dispatch(startGetArticles(date)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArticleList);
