import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import styles from './Settings.module.css';
import FacebookLogin from './FacebookLogin';
import TwitterLogin from './TwitterLogin';
import corsProxy from '../utilities/corsProxy';
import { functions } from '../firebase/firebase';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        siteUrl: props.settings.siteUrl || '',
        userName: props.settings.userName || '',
        password: props.settings.password || '',
        categories: props.settings.categories || [],
        tags: props.settings.tags || [],
      },
      processing: false,
    };
  }

  handleChange = (field, e) => {
    const { settings } = this.state;

    this.setState({
      settings: {
        ...settings,
        [field]: e.target.value,
      },
    });
  }

  handleSave = async (evt) => {
    evt.preventDefault();
    const { updateSettings } = this.props;
    const { settings } = this.state;

    this.setState({
      processing: true,
    });

    const response = await fetch(`${corsProxy}${settings.siteUrl}`);
    const html = await response.text();

    const startIndex = html.indexOf('<link rel=\'https://api.w.org/\'');
    const endIndex = html.indexOf('>', startIndex);
    const linkString = html.substr(startIndex, endIndex - startIndex + 1);

    const apiStartIndex = linkString.indexOf('href=') + 6;
    const apiEndIndex = linkString.indexOf('\'', apiStartIndex);
    const apiString = linkString.substr(apiStartIndex, apiEndIndex - apiStartIndex);

    const getWordPressCategories = functions.httpsCallable('getWordPressCategories');
    const wordPressCatsAndTags = await getWordPressCategories();

    const {
      categories,
      tags,
    } = wordPressCatsAndTags.data;

    updateSettings({
      ...settings,
      apiUrl: apiString,
      categories,
      tags,
    });

    this.setState({
      processing: false,
      settings: {
        ...settings,
        categories,
        tags,
      },
    });
  }

  render() {
    const { settings, processing } = this.state;

    return (
      <Loadable
        active={processing}
        spinner
        text="Gathering information from your blog site..."
        className={styles.container}
      >
        <div className={styles.sectionHeaderContainer}>
          Blog Site Settings
        </div>
        <form className={styles.form} onSubmit={this.handleSave}>
          <div className="form-group">
            <span>Site URL</span>
            <input
              required
              className="form-control"
              placeholder="http://www.example.com"
              value={settings.siteUrl}
              onChange={(text) => this.handleChange('siteUrl', text)}
            />
            {settings.categories && settings.categories.length > 0 && (
              <div className={styles.categoryContainer}>
                <small className={styles.categoryHeader}>Categories:</small>
                <small>
                  {settings.categories.map((category) => (
                    <small key={category.id} className={styles.category}>
                      {category.name}
                    </small>
                  ))}
                </small>
              </div>
            )}
            {settings.tags && settings.tags.length > 0 && (
              <div className={styles.categoryContainer}>
                <small className={styles.categoryHeader}>Tags:</small>
                <small>
                  {settings.tags.map((tag) => (
                    <span key={tag.id} className={styles.category}>
                      {tag.name}
                    </span>
                  ))}
                </small>
              </div>
            )}
          </div>
          <div className="form-group">
            <span>Site User Name:</span>
            <input
              required
              className="form-control"
              placeholder="user1"
              value={settings.userName}
              onChange={(text) => this.handleChange('userName', text)}
            />
          </div>
          <div className="form-group">
            <span>Site Password:</span>
            <input
              required
              type="password"
              className="form-control"
              placeholder="*********"
              value={settings.password}
              onChange={(text) => this.handleChange('password', text)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Update Settings
          </button>
        </form>
        <div className={styles.sectionHeaderContainer}>
          Social Media Connections
        </div>
        <div className={styles.sectionSubHeaderContainer}>
          <small>
            <p>
              Please use the buttons below to authorize or deauthorize social media connections.
              These connections are only used to allow posts to be shared on them when submitting an article.
            </p>
            <p>NOTE: Nothing will be sent to these connections without your knowledge.</p>
          </small>
        </div>
        <div className={styles.socialContainer}>
          <div className={styles.socialInnerContainer}>
            <FacebookLogin />
          </div>
          <div className={styles.socialInnerContainer}>
            <TwitterLogin />
          </div>
        </div>
      </Loadable>
    );
  }
}

Settings.propTypes = {
  settings: PropTypes.shape({
    siteUrl: PropTypes.string,
    userName: PropTypes.string,
    password: PropTypes.string,
    categories: PropTypes.array,
    tags: PropTypes.array,
  }).isRequired,
  updateSettings: PropTypes.func.isRequired,
};

export default Settings;
