import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmModal = (props) => {
  const {
    isOpen,
    confirmButtonText = 'Yes',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel,
    title = '',
    children,
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={onConfirm}>
            {confirmButtonText}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            {cancelButtonText}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ConfirmModal.defaultProps = {
  confirmButtonText: 'Yes',
  cancelButtonText: 'Cancel',
  title: '',
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default ConfirmModal;
