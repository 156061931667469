import React from 'react';
import { Provider } from 'react-redux';
import AuthUserContext from './AuthUserContext';
import { firebase } from '../firebase';
import { setUser } from '../actions/auth';
import configureStore from '../store/configureStore';
import { startGetSettings, startSetSettings } from '../actions/settings';

const store = configureStore();

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          store.dispatch(setUser({
            id: authUser.uid,
            email: authUser.email,
          }));
          store.dispatch(startGetSettings());
          this.setState({ authUser });
        } else {
          store.dispatch(setUser(null));
          store.dispatch(startSetSettings({}));
          this.setState({ authUser: null });
        }
      });
    }

    render() {
      const { authUser } = this.state;

      return (
        <Provider store={store}>
          <AuthUserContext.Provider value={authUser}>
            <Component />
          </AuthUserContext.Provider>
        </Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
