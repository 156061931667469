import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { functions } from '../firebase/firebase';
import styles from './PostArticleModal.module.css';

class PostArticleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      messageText: '',
      categories: {},
      tags: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { article } = nextProps;
    if (article) {
      const { title } = article;

      this.setState({
        messageText: title,
      });
    }
  }

  onChangeMessageText = (e) => {
    this.setState({
      messageText: e.target.value,
    });
  };

  onClickCategory = (category) => {
    const { categories } = this.state;

    if (categories[category.id]) {
      delete categories[category.id];
    } else {
      categories[category.id] = {
        ...category,
      };
    }

    this.setState({
      categories,
    });
  };

  onClickTag = (tag) => {
    const { tags } = this.state;

    if (tags[tag.id]) {
      delete tags[tag.id];
    } else {
      tags[tag.id] = {
        ...tag,
      };
    }

    this.setState({
      tags,
    });
  };

  onConfirm = async () => {
    const { facebookInfo, article, onSuccess } = this.props;
    const { link, image, title, content } = article;
    const { categories, tags, messageText } = this.state;

    const div = document.createElement('div');
    div.innerHTML = content;
    // const strippedContent = div.textContent || div.innerText || '';

    try {
      // const wordPressPost = functions.httpsCallable('wordPressPost');
      // const wordPressResponse = await wordPressPost({
      //   image,
      //   date: moment().format(),
      //   status: 'publish',
      //   title,
      //   content: `${strippedContent}<br><br><a href="${link}">${link}</a>`,
      //   categories: Object.keys(categories)
      //     .map((key) => categories[key].id)
      //     .join(','),
      //   tags: Object.keys(tags)
      //     .map((key) => tags[key].id)
      //     .join(','),
      // });

      // const { link: postLink } = wordPressResponse.data;

      if (
        facebookInfo &&
        facebookInfo.pageInfo.length > facebookInfo.selectedPage
      ) {
        const { access_token: accessToken, id } = facebookInfo.pageInfo[
          facebookInfo.selectedPage
        ];

        this.setState({
          status: 'Posting link to Facebook...',
        });

        await axios.post(
          `https://graph.facebook.com/${id}/feed?link=${encodeURIComponent(
            link,
          )}&message=${encodeURIComponent(
            messageText,
          )}&access_token=${accessToken}`,
        );
      }

      this.setState({
        status: 'Posting link to Twitter...',
      });

      try {
        const twitterPost = functions.httpsCallable('twitterPost');
        twitterPost({
          url: link,
          messageText,
          categories: Object.keys(categories).map(
            (key) => categories[key].name,
          ),
          tags: Object.keys(tags).map((key) => tags[key].id),
        });
      } catch (e) {
        console.log(`Error posting to twitter ${e}`);
      }

      this.setState({
        status: 'Finished.',
        categories: {},
        tags: {},
      });

      onSuccess();
    } catch (e) {
      console.log(`Error ${e}`);
    }
  };

  onCancel = () => {
    const { onCancel } = this.props;

    this.setState({
      categories: {},
      tags: {},
    });

    onCancel();
  };

  render() {
    const { isOpen, article, settings } = this.props;
    const { status, messageText, categories, tags } = this.state;

    if (!article) return null;

    return (
      <div>
        <Modal size="lg" isOpen={isOpen} toggle={this.onCancel}>
          <ModalHeader toggle={this.onCancel}>Post Information</ModalHeader>
          <ModalBody>
            <div className={styles.sectionContainer}>
              <div className={styles.categoryHeader}>Title</div>
              {article.title}
            </div>
            <div className={styles.sectionContainer}>
              <div className={styles.categoryHeader}>URL</div>
              {article.link}
            </div>
            {settings.categories && settings.categories.length > 0 && (
              <div className={styles.sectionContainer}>
                <div className={styles.categoryHeader}>
                  Choose Categories for Post (optional)
                </div>
                <div className={styles.categoryContainer}>
                  {settings.categories.map((category) => {
                    const categorySelected = !!categories[category.id];

                    return (
                      <div className={styles.category} key={category.id}>
                        <input
                          type="checkbox"
                          value={categorySelected}
                          onChange={() => this.onClickCategory(category)}
                        />
                        <span>{category.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {settings.tags && settings.tags.length > 0 && (
              <div className={styles.sectionContainer}>
                <div className={styles.categoryHeader}>
                  Choose Tags for Post (optional)
                </div>
                <div className={styles.categoryContainer}>
                  {settings.tags.map((tag) => {
                    const tagSelected = !!tags[tag.id];

                    return (
                      <div className={styles.category} key={tag.id}>
                        <input
                          type="checkbox"
                          value={tagSelected}
                          onChange={() => this.onClickTag(tag)}
                        />
                        <span>{tag.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <p>
              Creating this post will create a new word press post. Afterwards,
              it will submit a link to that post to linked social media
              platforms. Please add any additional text you would like to
              include on the social media posts:
            </p>
            <textarea
              className="form-control"
              rows={3}
              value={messageText}
              onChange={this.onChangeMessageText}
            />
            {status}
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onConfirm}
            >
              Create Post
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.onCancel}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

PostArticleModal.defaultProps = {
  article: null,
  settings: null,
};

PostArticleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  article: PropTypes.shape({
    title: PropTypes.string,
  }),
  settings: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }),
  onSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  facebookInfo: state.auth.facebookInfo,
  settings: state.settings.settings,
});

export default connect(mapStateToProps)(PostArticleModal);
