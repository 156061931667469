export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SET_FACEBOOK_INFO':
      return {
        ...state,
        facebookInfo: action.facebookInfo,
      }
    default:
      return state;
  }
}
