import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { firebase } from '../firebase';
import styles from './Login.module.css';

const INITIAL_STATE = {
  login: '',
  password: '',
  error: null,
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onTextChange = (field) => (e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  onLogin = (e) => {
    e.preventDefault();
    const { login, password } = this.state;
    const { history } = this.props;

    firebase.auth
      .signInWithEmailAndPassword(login, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push('/');
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  render() {
    const { login, password, error } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.loginBox}>
          <div className={styles.header}>
            <span className="material-icons">pages</span>
            <span>Post Central</span>
            <span className="material-icons">thumb_up</span>
          </div>
          <div className={styles.inputContainer}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">@</span>
              </div>
              <input placeholder="example@example.com" className="form-control" value={login} onChange={this.onTextChange('login')} />
            </div>
          </div>
          <div className={styles.inputContainerBottom}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text material-icons">lock</span>
              </div>
              <input placeholder="************" className="form-control" value={password} onChange={this.onTextChange('password')} type="password" />
            </div>
          </div>
          {error && (
            <div className={styles.errorContainer}>
              <span className={styles.error}>{error.message}</span>
            </div>
          )}
          <div className={styles.buttonBox}>
            <button type="button" className={styles.loginButton} onClick={this.onLogin} disabled={login.length === 0 || password.length === 0}>
              Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.any.isRequired,
};

export default withRouter(Login);
