export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_SITES':
      return {
        sites: action.sites,
      };
    case 'UPDATE_SITE':
      return {
        sites: state.sites.map((s) => {
          if (s.id === action.site.id) {
            return action.site;
          }
          return s;
        }),
      };
    default:
      return state;
  }
};
