import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './MainContainer.module.css';
import { firebase } from '../firebase';

class MainContainer extends Component {
  onLogout = (e) => {
    e.preventDefault();
    firebase.auth.signOut();
  };

  render() {
    const { user, children, location } = this.props;

    if (!user) return null;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span>{user.email}</span>
          <div className="btn-group" role="group">
            <Link
              className={`btn btn-info ${location.pathname === '/articles' ? 'active' : ''}`}
              to="/articles"
            >
              Article List
            </Link>
            <Link
              className={`btn btn-info ${location.pathname === '/sites' ? 'active' : ''}`}
              to="/sites"
            >
              Site List
            </Link>
            <Link
              className={`btn btn-info ${location.pathname === '/settings' ? 'active' : ''} `}
              to="/settings"
            >
              Settings
            </Link>
            <button
              type="button"
              className="btn btn-info"
              onClick={this.onLogout}
            >
              Logout
            </button>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {children}
        </div>
      </div>
    );
  }
}

MainContainer.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(MainContainer));
