import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { DelayInput } from 'react-delay-input';
import { Button, ButtonGroup } from 'reactstrap';
import styles from './ArticleListHeader.module.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class ArticleListHeader extends Component {
  state = {
    selectedDate: new Date(),
  };

  handleDateChange = (date) => {
    const { onNewDate } = this.props;

    this.setState({
      selectedDate: date,
    });

    onNewDate(date);
  }

  handleSearchTextChange = (e) => {
    const { updateFilters, currentFilters } = this.props;

    updateFilters({
      ...currentFilters,
      searchText: e.target.value,
    });
  }

  handleFilterButtonChange = (field) => {
    const { updateFilters, currentFilters } = this.props;

    let updatedFilters = {};
    switch (field) {
      case 'showUnpublished':
        updatedFilters = {
          showUnpublished: true,
          showPublished: false,
          showHidden: false,
        };
        break;
      case 'showPublished':
        updatedFilters = {
          showUnpublished: false,
          showPublished: true,
          showHidden: false,
        };
        break;
      case 'showHidden':
        updatedFilters = {
          showUnpublished: false,
          showPublished: false,
          showHidden: true,
        };
        break;
      default:
        break;
    }

    updateFilters({
      ...currentFilters,
      ...updatedFilters,
    });
  }

  render() {
    const { onPollAll, currentFilters } = this.props;
    const { selectedDate } = this.state;

    const {
      searchText,
      showUnpublished,
      showPublished,
      showHidden,
    } = currentFilters;

    return (
      <div className={styles.headerBox}>
        <div className={styles.header}>
          <span className={styles.title}>Articles</span>
          <div className={styles.filterBox}>
            <div>
              <DatePicker
                className="form-control"
                selected={selectedDate}
                onChange={this.handleDateChange}
              />
            </div>
            <div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text material-icons">search</span>
                </div>
                <DelayInput
                  minLength={2}
                  delayTimeout={300}
                  className="form-control"
                  value={searchText}
                  onChange={this.handleSearchTextChange}
                />
              </div>
            </div>
            <div>
              <ButtonGroup>
                <Button
                  color="primary"
                  onClick={() => this.handleFilterButtonChange('showUnpublished')}
                  active={showUnpublished}
                >
                  Not Posted
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleFilterButtonChange('showPublished')}
                  active={showPublished}
                >
                  Posted
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleFilterButtonChange('showHidden')}
                  active={showHidden}
                >
                  Hidden
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-info"
            onClick={onPollAll}
          >
            Poll All Sites
          </button>
        </div>
      </div>
    );
  }
}

ArticleListHeader.propTypes = {
  onPollAll: PropTypes.func.isRequired,
  onNewDate: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  currentFilters: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    showUnpublished: PropTypes.bool.isRequired,
    showPublished: PropTypes.bool.isRequired,
    showHidden: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ArticleListHeader;
