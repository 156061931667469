import React, { Component } from 'react';
import styles from './Site.module.css';

class CreateSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
    };
  }

  onUrlChange = (e) => {
    this.setState({
      url: e.target.value,
    });
  }

  onSave = (e) => {
    e.preventDefault();
    this.props.onCreate(this.state.url);
    this.setState({
      url: '',
    });
    this.urlInput.focus();
  }

  render() {
    return (
      <form onSubmit={this.onSave} className={styles.container}>
        <div className={styles.innerContainer}>
          <input
            className='form-control'
            placeholder='https://www.example.com'
            value={this.state.url}
            onChange={this.onUrlChange}
            ref={(input) => { this.urlInput = input; }}
          />
          <div className={styles.buttonBox}>
            <button className='btn btn-success d-flex align-items-center' onClick={this.onSave} disabled={this.state.url === ''}>
              <span className='material-icons'>add_circle</span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default CreateSite;