import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Site from './Site';
import styles from './SiteList.module.css';
import CreateSite from './CreateSite';
import { startUpdateSite, startDeleteSite, startCreateSite } from '../actions/sites';
import ConfirmModal from './ConfirmModal';

class SiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteOpen: false,
      deletingSite: null,
      importDialogOpen: false,
      importList: '',
    };
  }

  onCreate = (url) => {
    const { createSite } = this.props;
    createSite(url);
  };

  onUpdate = (site) => {
    const { updateSite } = this.props;
    updateSite({
      ...site,
      url: site.url,
    });
  };

  onDelete = (site) => {
    this.setState({
      confirmDeleteOpen: true,
      deletingSite: site,
    });
  };

  onCancelDelete = (e) => {
    e.preventDefault();
    this.setState({
      confirmDeleteOpen: false,
      deletingSite: null,
    });
  };

  onConfirmDelete = (e) => {
    e.preventDefault();
    const { deleteSite } = this.props;
    const { deletingSite } = this.state;

    deleteSite(deletingSite);
    this.setState({
      confirmDeleteOpen: false,
      deletingSite: null,
    });
  };

  onImport = (e) => {
    e.preventDefault();
    this.setState({
      importDialogOpen: true,
    });
  };

  isURL = (str) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' // protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
      + '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return pattern.test(str);
  };

  onImportConfirm = (e) => {
    e.preventDefault();
    const { importList } = this.state;
    const siteList = importList.split('\n');

    if (siteList.length > 0) {
      siteList.forEach((url) => {
        if (this.isURL(url)) {
          this.onCreate(url);
        }
      });
    }
    this.setState({
      importList: '',
      importDialogOpen: false,
    });
  };

  onCancelImport = (e) => {
    e.preventDefault();
    this.setState({
      importList: '',
      importDialogOpen: false,
    });
  };

  onImportListChange = (e) => {
    this.setState({
      importList: e.target.value,
    });
  };

  render() {
    const { sites } = this.props;
    const { confirmDeleteOpen, importDialogOpen, importList } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.headerBox}>
          <div className={styles.header}>
            <span>Sites</span>
            <button type="button" className="btn btn-info" onClick={this.onImport}>
              Import Site List
            </button>
          </div>
          <CreateSite onCreate={this.onCreate} />
        </div>
        <div className={styles.contentBox}>
          {!sites || sites.length === 0 ? (
            <div className={styles.statusText}>
              You do not have any sites defined. Use the form above to create
              your first site.
            </div>
          ) : (
            sites.map((site) => (
              <Site
                key={site.id}
                site={site}
                onDelete={this.onDelete}
                onUpdate={this.onUpdate}
              />
            ))
          )}
        </div>
        <ConfirmModal
          isOpen={confirmDeleteOpen}
          title="Delete Confirmation"
          onConfirm={this.onConfirmDelete}
          onCancel={this.onCancelDelete}
        >
          <div>Are you sure you want to delete this site?</div>
        </ConfirmModal>

        <ConfirmModal
          isOpen={importDialogOpen}
          title="Import Site List"
          onConfirm={this.onImportConfirm}
          onCancel={this.onCancelImport}
          confirmButtonText="Import Sites"
        >
          <div>Enter one site URL per line:</div>
          <div>
            <textarea
              className="form-control"
              rows="10"
              value={importList}
              onChange={this.onImportListChange}
            />
          </div>
        </ConfirmModal>
      </div>
    );
  }
}

SiteList.defaultProps = {
  sites: [],
};

SiteList.propTypes = {
  createSite: PropTypes.func.isRequired,
  updateSite: PropTypes.func.isRequired,
  deleteSite: PropTypes.func.isRequired,
  sites: PropTypes.array,
};

const mapStateToProps = (state) => ({
  sites: state.sites.sites,
});

const mapDispatchToProps = (dispatch) => ({
  createSite: (url) => dispatch(startCreateSite(url)),
  updateSite: (site) => dispatch(startUpdateSite(site)),
  deleteSite: (site) => dispatch(startDeleteSite(site)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteList);
