import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import styles from './FacebookLogin.module.css';
import { setFacebookInfo as _setFacebookInfo } from '../actions/auth';
import facebookLogo from '../images/f-ogo_RGB_HEX-58.png';

class FacebookLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facebookAuthorized: false,
    };
  }

  componentDidMount() {
    if (!window.fbAsyncInit) {
      window.fbAsyncInit = this.initFacebook;
      this.facebookScriptSetup(document, 'script', 'facebook-jssdk');
    } else {
      this.updateLoginStatus();
    }
  }

  initFacebook = () => {
    window.FB.init({
      appId: '198963890893418',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v3.1',
    });

    this.updateLoginStatus();
  }

  facebookScriptSetup = (d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }

  updateLoginStatus = () => {
    const { facebookInfo } = this.props;

    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        const { userID, accessToken } = response.authResponse;

        this.setState({
          facebookAuthorized: true,
        });

        axios
          .get(
            `https://graph.facebook.com/${userID}/accounts?access_token=${accessToken}`,
          )
          .then((res) => {
            const { setFacebookInfo } = this.props;
            if (res.data.data.length > 0) {
              setFacebookInfo({
                userID,
                accessToken,
                pageInfo: res.data.data,
                selectedPage: facebookInfo && facebookInfo.selectedPage >= 0 ? facebookInfo.selectedPage : 0,
              });
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } else {
        this.setState({
          facebookAuthorized: false,
        });
      }
    });
  };

  authorizeFacebook = () => {
    window.FB.login(
      () => {
        this.updateLoginStatus();
      },
      {
        scope: 'manage_pages,publish_pages',
      },
    );
  };

  deauthorizeFacebook = () => {
    window.FB.logout(() => {
      this.updateLoginStatus();
    });
  };

  handleSelectPage = (e) => {
    const { setFacebookInfo, facebookInfo } = this.props;
    setFacebookInfo({
      ...facebookInfo,
      selectedPage: e.target.value,
    });
  };

  render() {
    const { facebookAuthorized } = this.state;
    const { facebookInfo } = this.props;
    if (facebookAuthorized) {
      return (
        <div className={styles.container}>
          <div className={styles.rowContainer}>
            <div className={styles.innerContainer}>
              <div>
                <div className={styles.innerImageContainer}>
                  <span className="material-icons text-success">check_circle</span>
                </div>
              </div>
              <img src={facebookLogo} className={styles.logo} alt="Facebook Logo" />
              <span className={styles.authText}>Facebook</span>
            </div>
            <button
              type="button"
              className="btn btn-warning"
              onClick={this.deauthorizeFacebook}
            >
              Deauthorize
            </button>
          </div>
          {facebookInfo && facebookInfo.pageInfo && (
            <div className={styles.pageContainer}>
              <span className={styles.pageTitle}>
                Please select the page to use for article posts:
              </span>
              <select
                className="form-control"
                value={facebookInfo.selectedPage}
                onChange={this.handleSelectPage}
              >
                {facebookInfo.pageInfo.map((page, index) => (
                  <option key={page.id} value={index}>
                    {page.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.rowContainer}>
          <div className={styles.innerContainer}>
            <div>
              <div className={styles.innerImageContainer}>
                <span className="material-icons text-danger">offline_bolt</span>
              </div>
            </div>
            <img src={facebookLogo} className={styles.logo} alt="Facebook Logo" />
            <span className={styles.authText}>Facebook</span>
          </div>
          <button
            type="button"
            className="btn btn-warning"
            onClick={this.authorizeFacebook}
          >
            Authorize
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  facebookInfo: state.auth.facebookInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setFacebookInfo: (facebookInfo) => dispatch(_setFacebookInfo(facebookInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacebookLogin);
