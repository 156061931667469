import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SiteStatus from './SiteStatus';
import styles from './Site.module.css';
import { functions } from '../firebase/firebase';

class Site extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      url: props.site ? props.site.url : '',
      isProcessing: false,
    };
  }

  componentDidUpdate() {
    const { isEditing } = this.state;

    if (isEditing) {
      this.urlInput.focus();
    }
  }

  onEdit = (e) => {
    e.preventDefault();
    this.setState({
      isEditing: true,
    });
  }

  onDelete = (e) => {
    e.preventDefault();
    const { onDelete, site } = this.props;
    onDelete(site);
  }

  onUrlChange = (e) => {
    this.setState({
      url: e.target.value,
    });
  }

  onUpdate = (e) => {
    e.preventDefault();
    const { onUpdate, site } = this.props;

    onUpdate(site);
    this.setState({
      isEditing: false,
    });
  }

  onCancel = (e) => {
    e.preventDefault();
    const { site } = this.props;

    this.setState({
      isEditing: false,
      url: site.url,
    });
  }

  onParse = async () => {
    this.setState({
      isProcessing: true,
    });

    const { site } = this.props;
    const parseSite = functions.httpsCallable('parseSite');
    await parseSite(site);

    this.setState({
      isProcessing: false,
    });
  }

  render() {
    const {
      url,
      isEditing,
      isProcessing,
    } = this.state;

    const { site, hideButtons } = this.props;

    return (
      <div>
        {isEditing ? (
          <form onSubmit={this.onUpdate} className={styles.container}>
            <div className={styles.innerContainer}>
              <input
                className={styles.textField}
                placeholder="URL"
                value={url}
                onChange={this.onUrlChange}
                ref={(input) => { this.urlInput = input; }}
              />
              <div className={styles.buttonBox}>
                <div className="btn-group" role="group">
                  <button type="button" className="btn btn-success d-flex align-items-center" onClick={this.onUpdate} disabled={url === ''}>
                    <span className="material-icons">check_circle</span>
                  </button>
                  <button type="button" className="btn btn-secondary d-flex align-items-center" onClick={this.onCancel} disabled={url === ''}>
                    <span className="material-icons">cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <div className={styles.container}>
            <div className={styles.innerContainer}>
              <div className={styles.urlText}>{url}</div>
              <SiteStatus site={site} />
              {!isProcessing && !hideButtons && (
                <div className={styles.buttonBox}>
                  <div className="btn-group" role="group">
                    <button type="button" className="btn btn-success d-flex align-items-center" onClick={this.onParse} title="Connect to RSS feed now">
                      <span className="material-icons">rss_feed</span>
                    </button>
                    <button type="button" className="btn btn-primary d-flex align-items-center" onClick={this.onEdit}>
                      <span className="material-icons">edit</span>
                    </button>
                    <button type="button" className="btn btn-danger d-flex align-items-center" onClick={this.onDelete}>
                      <span className="material-icons">delete</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Site.defaultProps = {
  hideButtons: false,
};

Site.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  site: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  hideButtons: PropTypes.bool,
};

export default Site;
