import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import withAuthorization from './withAuthorization';
import { functions } from '../firebase/firebase';

class WordPressResponse extends Component {
  componentDidMount() {
    const { location, history } = this.props;
    const { search } = location;
    const values = queryString.parse(search);
    const {
      code,
    } = values;

    if (code) {
      const wordPressCallBack = functions.httpsCallable('wordPressCallBack');
      wordPressCallBack({
        code,
      });
    }
    history.push('/');
  }

  render() {
    return <div />;
  }
}

WordPressResponse.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(WordPressResponse));
