import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthUserContext from './AuthUserContext';
import { firebase } from '../firebase';

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const { history } = this.props;

      firebase.auth.onAuthStateChanged((authUser) => {
        if (!authCondition(authUser)) {
          history.push('/login');
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => (authUser ? <Component /> : null)}
        </AuthUserContext.Consumer>
      );
    }
  }

  WithAuthorization.propTypes = {
    history: PropTypes.object.isRequired,
  };

  return withRouter(WithAuthorization);
};

export default withAuthorization;
