import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyCpZ86-JQKkTmc2bM3SvdK7U41eR6GyY9g',
  authDomain: 'boardtownblast-30782.firebaseapp.com',
  databaseURL: 'https://boardtownblast-30782.firebaseio.com',
  projectId: 'boardtownblast-30782',
  storageBucket: 'boardtownblast-30782.appspot.com',
  messagingSenderId: '775083504210',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const functions = firebase.functions();

export { db, auth, functions };
