import { db } from '../firebase';

const setSites = (sites) => ({
  type: 'SET_SITES',
  sites,
});

const updateSite = (site) => ({
  type: 'UPDATE_SITE',
  site,
});

const startGetSites = () => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  return db.getSites(uid).then((sites) => {
    dispatch(setSites(sites));
    db.setSiteListWatcher(uid, (site) => {
      dispatch(updateSite(site));
    });
  });
};


const startUpdateSite = (site) => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  return db.updateSite(uid, site).then(() => dispatch(startGetSites()));
};

const startCreateSite = (url) => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  return db.createSite(uid, url).then(() => dispatch(startGetSites()));
};


const startDeleteSite = (site) => (dispatch, getState) => {
  const uid = getState().auth.user.id;
  return db.deleteSite(uid, site).then(() => dispatch(startGetSites()));
};


export {
  setSites,
  startGetSites,
  startUpdateSite,
  startCreateSite,
  startDeleteSite,
};
