import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import siteReducer from '../reducers/site';
import articleReducer from '../reducers/article';
import settingsReducer from '../reducers/settings';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      sites: siteReducer,
      articles: articleReducer,
      settings: settingsReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};

export default configureStore;
