const setUser = (user) => ({
  type: 'SET_USER',
  user: user,
});

const setFacebookInfo = (facebookInfo) => ({
  type: 'SET_FACEBOOK_INFO',
  facebookInfo,
})

export { setUser, setFacebookInfo };