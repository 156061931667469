import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './SiteStatus.module.css';

const SiteStatus = (props) => {
  const { site } = props;

  const {
    lastStatus,
    lastStatusDate,
    lastStatusText,
  } = site;

  if (!lastStatus || !lastStatusDate) {
    return null;
  }

  let icon = null;
  switch (lastStatus) {
    case 'error':
      icon = <span className="material-icons text-danger" title={site.lastStatusText}>warning</span>;
      break;
    case 'success':
      icon = <span className="material-icons text-success">check</span>;
      break;
    default:
      icon = <span className="material-icons text-default">autorenew</span>;
      break;
  }

  return (
    <div className={styles.container}>
      {icon}
      {lastStatusText}
      {' @ '}
      {moment(lastStatusDate).format('YYYY-MM-DD HH:mm')}
    </div>
  );
};

SiteStatus.propTypes = {
  site: PropTypes.shape({
    lastStatus: PropTypes.string,
    lastStatusDate: PropTypes.string,
    lastStatusText: PropTypes.string,
  }).isRequired,
};

export default SiteStatus;
