import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import withAuthorization from './withAuthorization';
import { functions } from '../firebase/firebase';

class TwitterResponse extends Component {
  componentDidMount() {
    const { location, history } = this.props;
    const { search } = location;
    const values = queryString.parse(search);
    const {
      oauth_token: oauthToken,
      oauth_verifier: oauthVerifier,
    } = values;

    if (oauthToken && oauthVerifier) {
      const twitterCallback = functions.httpsCallable('twitterCallback');
      twitterCallback({
        oauthToken,
        oauthVerifier,
      });
    }
    history.push('/');
  }

  render() {
    return <div />;
  }
}

TwitterResponse.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(TwitterResponse));
