import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { startGetArticles } from '../actions/articles';
import { startGetSites } from '../actions/sites';
import ArticleList from './ArticleList';
import withAuthorization from './withAuthorization';
import MainContainer from './MainContainer';

class ArticlesContainer extends Component {
  componentDidMount() {
    const { getArticles, getSites } = this.props;
    getArticles();
    getSites();
  }

  render() {
    return (
      <MainContainer>
        <ArticleList />
      </MainContainer>
    );
  }
}

ArticlesContainer.propTypes = {
  getArticles: PropTypes.func.isRequired,
  getSites: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getArticles: () => dispatch(startGetArticles()),
  getSites: () => dispatch(startGetSites()),
});

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(connect(mapStateToProps, mapDispatchToProps)(ArticlesContainer));
