import React from 'react';
import { Badge, Card, CardText, CardBody, CardTitle, CardSubtitle, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import styles from './Article.module.css';

const Article = (props) => {
  const { article, onPost, onHide, searchText } = props;
  const { title, contentSnippet, categories, creator, isoDate, image, posted, postedOn, hidden } = article;

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.image} />
        <CardBody>
          <CardTitle>
            {searchText ? (
              <Highlighter highlightClassName={styles.highlightText} searchWords={[searchText]} autoEscape textToHighlight={title} />
            ) : (
              <span>{title}</span>
            )}
          </CardTitle>
          <CardSubtitle>
            {'by '}
            {creator}
            {' on '}
            {moment(isoDate).format('YYYY-MM-DD hh:mm a')}
          </CardSubtitle>
        </CardBody>
        <div
          style={{
            background: `url(${image})`,
            height: '200px',
            width: '500px',
            backgroundSize: '500px',
          }}
        />
        <CardBody>
          <CardText className={styles.contentBox}>
            {searchText ? (
              <Highlighter highlightClassName={styles.highlightText} searchWords={[searchText]} autoEscape textToHighlight={contentSnippet} />
            ) : (
              <span>{contentSnippet}</span>
            )}
          </CardText>
          {onPost && (
            <CardText className={styles.tagBox}>
              {categories
                && categories.map((cat, index) => (
                  <span key={index} className={styles.badgeContainer}>
                    <Badge key={index} color="primary">
                      {cat}
                    </Badge>
                  </span>
                ))}
            </CardText>
          )}
          <div className={styles.buttonContainer}>
            {onPost && (
              <button type="button" className="btn btn-success d-flex align-items-center" onClick={() => onPost(article)} title="Post Article">
                <span className="material-icons">publish</span>
              </button>
            )}
            <button
              type="button"
              className="btn btn-warning d-flex align-items-center"
              onClick={() => onHide(article)}
              title={hidden ? 'Unhide Article' : 'Hide Article'}
            >
              <span className="material-icons">{hidden ? 'visibility' : 'visibility_off'}</span>
            </button>
          </div>
        </CardBody>
        {posted && (
          <CardFooter>
            {'Posted on'}
            {postedOn}
          </CardFooter>
        )}
        <CardFooter>
          <span>{article.siteOrigin}</span>
        </CardFooter>
      </Card>
    </div>
  );
};

Article.defaultProps = {
  searchText: '',
};

Article.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    contentSnippet: PropTypes.string,
    categories: PropTypes.array,
    creator: PropTypes.string.isRequired,
    isoDate: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  onPost: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

export default Article;
