import moment from 'moment';
import { db } from './firebase';

export const doCreateUser = (id, username, email) => db.ref(`users/${id}`).set({
  username,
  email,
});

export const onceGetUsers = () => db.ref('users').once('value');

export const createSite = (uid, url) => db.ref(`users/${uid}/sites`).push({
  url,
  type: 'rss',
  lastStatus: null,
  lastStatusText: null,
});

export const getSites = (uid) => new Promise((resolve) => {
  db.ref(`users/${uid}/sites`)
    .once('value')
    .then((snapshot) => {
      const sites = [];
      snapshot.forEach((childSnapshot) => {
        sites.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });

      resolve(sites);
    });
});

export const setSiteListWatcher = (uid, callback) => {
  db.ref(`users/${uid}/sites`).on('child_changed', (data) => {
    callback({
      id: data.key,
      ...data.val(),
    });
  });
};

export const updateSite = (uid, site) => db.ref(`users/${uid}/sites/${site.id}`).set(site);

export const deleteSite = (uid, site) => db.ref(`users/${uid}/sites/${site.id}`).remove();

export const createArticle = (uid, article) => new Promise((resolve) => {
  db.ref(`users/${uid}/articles`)
    .orderByChild('link')
    .equalTo(article.link)
    .once('value')
    .then((snapshot) => {
      if (!snapshot.val()) {
        const fixedArticle = {
          ...article,
        };
        delete fixedArticle.id;

        db.ref(`users/${uid}/articles`).push({
          ...fixedArticle,
          createDate: moment().format(),
          searchDate: fixedArticle.isoDate
            ? moment(fixedArticle.isoDate).format('YYYYMMDD')
            : moment(fixedArticle.pubDate).format('YYYYMMDD'),
        }).then(() => resolve());
      } else {
        resolve();
      }
    });
});

export const updateArticle = (uid, article) => db.ref(`users/${uid}/articles/${article.id}`).set(article);

const getImageUrl = (image, site) => {
  const siteUrl = new URL(site).origin;
  if (image && !image.startsWith('http')) {
    return `${siteUrl}${image.startsWith('/') ? '' : '/'}${image}`;
  }
  return image;
};

const getSiteOrigin = (site) => {
  try {
    return new URL(site).origin;
  } catch (e) {
    return site;
  }
};

export const getArticles = (uid, date) => new Promise((resolve) => {
  db.ref(`users/${uid}/articles`)
    .orderByChild('searchDate')
    .equalTo(date || moment().format('YYYYMMDD'))
    .once('value')
    .then((snapshot) => {
      const articles = [];
      snapshot.forEach((childSnapshot) => {
        const article = childSnapshot.val();
        articles.push({
          ...article,
          image: getImageUrl(article.image, article.site),
          siteOrigin: getSiteOrigin(article.site),
          id: childSnapshot.key,
        });
      });

      resolve(articles);
    });
});

export const setArticleListWatcher = (uid, date, callback) => {
  db.ref(`users/${uid}/articles`)
    .orderByChild('searchDate')
    .equalTo(date || moment().format('YYYYMMDD'))
    .on('child_changed', (data) => {
      callback({
        ...data.val(),
        id: data.key,
      });
    });
};

export const getTwitterInfoUpdate = (uid, callback) => {
  db.ref(`users/${uid}/twitterInfo`).on('value', callback);
};

export const clearTwitterInfo = (uid) => db.ref(`users/${uid}/twitterInfo`).set({});

export const getSettings = (uid) => new Promise((resolve) => {
  db.ref(`users/${uid}/settings`)
    .once('value')
    .then((snapshot) => {
      resolve(snapshot.val());
    });
});

export const setSettings = (uid, settings) => db.ref(`users/${uid}/settings`).set(settings);
