import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainContainer from './MainContainer';
import { startSetSettings } from '../actions/settings';
import Settings from './Settings';
import withAuthorization from './withAuthorization';

class SettingsContainer extends Component {
  updateSettings = (settings) => {
    const { setSettings } = this.props;
    setSettings(settings);
  }

  render() {
    const { settings } = this.props;

    if (!settings) {
      return null;
    }

    return (
      <MainContainer>
        <Settings settings={settings} updateSettings={this.updateSettings} />
      </MainContainer>
    );
  }
}

SettingsContainer.defaultProps = {
  settings: null,
};

SettingsContainer.propTypes = {
  setSettings: PropTypes.func.isRequired,
  settings: PropTypes.any,
};

const mapStateToProps = (state) => ({
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setSettings: (settings) => dispatch(startSetSettings(settings)),
});

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(connect(mapStateToProps, mapDispatchToProps)(SettingsContainer));
