import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';
import Login from './Login';
import ArticlesContainer from './ArticlesContainer';
import withAuthentication from './withAuthentication';
import TwitterResponse from './TwitterResponse';
import SitesContainer from './SitesContainer';
import SettingsContainer from './SettingsContainer';
import WordPressResponse from './WordPressResponse';

const App = () => (
  <Router>
    <div>
      <Route exact path="/" component={() => <Redirect to="/articles" />} />
      <Route exact path="/articles" component={ArticlesContainer} />
      <Route exact path="/sites" component={SitesContainer} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/settings" component={SettingsContainer} />
      <Route path="/twitterResponse" component={TwitterResponse} />
      <Route path="/wpResponse" component={WordPressResponse} />
    </div>
  </Router>
);

export default withAuthentication(App);
